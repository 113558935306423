import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import Ceremony from "./components/v2/Ceremony";
import Benediction from "./components/v2/Benediction";
import Reception from "./components/v2/Reception";
import Lodging from "./components/v2/Lodging";
import Separator from "./components/v2/Separator";
import { CC_BN_R_H, CC_BN_R, BN_R_H, BN_R, BN } from "./constants";

import "./index.css";

const App = lazy(() => import("./App"));

const Loader = () => <>...</>;

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Suspense fallback={<Loader />}>
        <App />
      </Suspense>
    ),
    errorElement: (
      <Suspense fallback={<Loader />}>
        <App />
      </Suspense>
    ),
    children: [
      {
        path: `/${CC_BN_R_H}`,
        element: (
          <Suspense fallback={<Loader />}>
            <Ceremony />
            <Separator />
            <Benediction />
            <Separator />
            <Reception />
            <Separator />
            <Lodging />
            <Separator />
          </Suspense>
        ),
      },
      {
        path: `/${CC_BN_R}`,
        element: (
          <Suspense fallback={<Loader />}>
            <Ceremony />
            <Separator />
            <Benediction />
            <Separator />
            <Reception />
            <Separator />
          </Suspense>
        ),
      },
      {
        path: `/${BN_R_H}`,
        element: (
          <Suspense fallback={<Loader />}>
            <Benediction />
            <Separator />
            <Reception />
            <Separator />
            <Lodging />
            <Separator />
          </Suspense>
        ),
      },
      {
        path: `/${BN_R}`,
        element: (
          <Suspense fallback={<Loader />}>
            <Benediction />
            <Separator />
            <Reception />
            <Separator />
          </Suspense>
        ),
      },
      {
        path: `/${BN}`,
        element: (
          <Suspense fallback={<Loader />}>
            <Benediction />
            <Separator />
          </Suspense>
        ),
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if (module.hot) {
  module.hot.accept();
}

import React from 'react';
import { useMediaQuery } from 'usehooks-ts'
import './Lodging.css';

const Lodging = () => {
  const isMobile = useMediaQuery('(max-width: 1000px)');

  return (
    <section className='Lodging container' id='hebergement'>
      {isMobile ? (
        <>
          <h2>Hébergement</h2>
          <div className='ta-c'>
            <p>
              Nous vous avons réservé une chambre,
              <br/>
              afin de vous reposer après la soirée.
            </p>

            <img src="/images/1615_00-2023-09-18-1425.jpeg" />
            <br/>

            <p>
              Dimanche matin, nous vous accueillerons autour d’un petit-déjeuner,
              <br/>avant de nous séparer.
            </p>

            <img src="/images/pixabay-petit-dejeuner-breakfast-palacioerick-4234067_1920-1024x683.jpeg" />
          </div>
        </>
      ) : (
        <div>
          <h2>Hébergement</h2>
          <p className='ta-c'>
            Nous vous avons réservé une chambre,
            <br/>
            afin de vous reposer après la soirée.
            <br /><br />
            Dimanche matin, nous vous accueillerons autour d’un petit-déjeuner,
            <br/>avant de nous séparer.
          </p>
          <br/><br/>
          <div className='content-side'>
            <div className="content-side-content">
              <img src="/images/1615_00-2023-09-18-1425.jpeg" />
            </div>
            <div className="content-side-content">
              <img src="/images/pixabay-petit-dejeuner-breakfast-palacioerick-4234067_1920-1024x683.jpeg" />
            </div>
          </div>
        </div>
      )}
    </section>
  )
}

export default Lodging;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot, faClock } from '@fortawesome/free-solid-svg-icons'
import { useMediaQuery } from 'usehooks-ts'
import './Reception.css';

const Reception = () => {
  const isMobile = useMediaQuery('(max-width: 1000px)');

  return (
    <section className='Reception container' id='reception'>
      {isMobile ? (
        <>
          <h2>Réception</h2>
          <div className="ta-c">
            <div className="text-with-icon">
              <FontAwesomeIcon icon={faClock} fontSize={30} className='primary-color' />
              <p className='date'>
                À partir de 19h30
              </p>
            </div>
            <br/><br/>
            <div className='flex flex-se map-link'>
              <div className="text-with-icon">
                <FontAwesomeIcon icon={faLocationDot} fontSize={30} className='primary-color' />
                <p className='address'>
                  <a href='https://maps.app.goo.gl/dY6y4JT4gbtAemvE9' target='_blank' rel='noreferrer'>
                    Château de Boucq
                    <br />
                    23 rue de Rangeval
                    <br/>54200 Boucq
                  </a>
                </p>
              </div>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2630.71081905138!2d5.756187177030701!3d48.74922057131751!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47eb4d6e31e0f41f%3A0xe571f3f7f92cab2e!2sCh%C3%A2teau%20de%20Boucq!5e0!3m2!1sfr!2sfr!4v1706652436929!5m2!1sfr!2sfr"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                title="Marie de Toul"  
              />
            </div>
          </div>
          <br/><br/>
          <img src="/images/20240129_155151.jpeg" />
        </>
      ) : (
        <div className='content-side'>
          <div className="content-side-content">
            <img src="/images/20240129_155151.jpeg" />
          </div>
          <div className='content-side-content invert'>
            <h2>Réception</h2>
              <div className="ta-c">
                  <div className="text-with-icon">
                    <FontAwesomeIcon icon={faClock} fontSize={30} className='primary-color' />
                    <p className='date'>
                      À partir de 19h30
                    </p>
                  </div>
                  <div className="text-with-icon">
                    <FontAwesomeIcon icon={faLocationDot} fontSize={30} className='primary-color' />
                    <p className='address'>
                      Château de Boucq
                      <br />
                      23 rue de Rangeval
                      <br/>54200 Boucq
                    </p>
                  </div>
                </div>
          </div>
        </div>
      )}
    </section>
  )
}

export default Reception;

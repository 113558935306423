import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faClock } from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "usehooks-ts";
import "./Benediction.css";

const Benediction = () => {
  const isMobile = useMediaQuery("(max-width: 1000px)");

  return (
    <section className="Benediction container" id="benediction-nuptiale">
      {isMobile ? (
        <>
          <h2>Bénédiction nuptiale</h2>
          <div className="ta-c">
            <div className="text-with-icon">
              <FontAwesomeIcon
                icon={faClock}
                fontSize={30}
                className="primary-color"
              />
              <p className="date">
                Samedi 17 août 2024
                <br />à 15h30
              </p>
            </div>
            <br />
            <br />
            <div className="flex flex-se map-link">
              <div className="text-with-icon">
                <FontAwesomeIcon
                  icon={faLocationDot}
                  fontSize={30}
                  className="primary-color"
                />
                <p className="address">
                  <a
                    href="https://maps.app.goo.gl/YSocYQhNHghu9jcV7"
                    target="_blank"
                    rel="noreferrer"
                  >
                    23 rue de Rangeval
                    <br />
                    54200 Boucq
                  </a>
                </p>
              </div>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11676.72437361926!2d5.7522888002572055!3d48.74532905072359!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47eb4d71972a7a3b%3A0x15bf96f98faa2ee1!2s23%20Rue%20de%20Rangeval%2C%2054200%20Boucq!5e0!3m2!1sfr!2sfr!4v1706652083676!5m2!1sfr!2sfr"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                title="Marie de Toul"
              />
            </div>
          </div>
          <br />
          <br />

          <img src="/images/bagues.jpeg" />

          <p className="ta-c">
            À la suite de la bénédiction nuptiale,
            <br />
            nous aurons le plaisir de vous accueillir autour d’un vin d’honneur.
          </p>
        </>
      ) : (
        <div className="content-side">
          <div className="content-side-content">
            <h2>Bénédiction nuptiale</h2>
            <div className="ta-c">
              <div className="text-with-icon">
                <FontAwesomeIcon
                  icon={faClock}
                  fontSize={30}
                  className="primary-color"
                />
                <p className="date">
                  Samedi 17 août 2024
                  <br />à 15h30
                </p>
              </div>
              <div className="text-with-icon">
                <FontAwesomeIcon
                  icon={faLocationDot}
                  fontSize={30}
                  className="primary-color"
                />
                <p className="address">
                  23 rue de Rangeval
                  <br />
                  54200 Boucq
                </p>
              </div>
              <p>
                À la suite de la bénédiction nuptiale,
                <br />
                nous aurons le plaisir de vous accueillir autour d’un vin
                d’honneur.
              </p>
            </div>
          </div>
          <div className="content-side-content">
            <img src="/images/bagues.jpeg" />
          </div>
        </div>
      )}
    </section>
  );
};

export default Benediction;
